:global {
  .default-MuiDrawer-paper {
    background-color: transparent;
  }
}

.sidePanel {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  max-width: 576px;
  background-color: #ffffff;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;

  .header {
    position: sticky;
    top: 0;
    padding: 18px 0 0;
    margin-bottom: 24px;
    background-color: #ffffff;
    z-index: 100;

    .closeButton {
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
    }

    .thing {
      display: none;
      position: absolute;
      top: 4px;
      left: calc(50% - 32px / 2);
      width: 32px;
      height: 4px;
      background: #cacaca;
      border-radius: 2px;
    }

    h6 {
      margin-bottom: 18px;
    }

    .divider {
      margin: 0 -16px;
    }
  }


  @media (max-width: 650px) {
    height: 90vh;
    border-radius: 16px 16px 0 0;
    .header {
      .thing {
        display: inline-block;
      }
    }
  }
}
