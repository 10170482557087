.mb16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px;
}

.step {
  margin-bottom: 32px;
}

.bottomAlert {
  flex: 1 1 auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
