.stepsMenu {
  margin-right: 28px;
  flex: 0 0 260px;

  .stepsMenuItem {
    cursor: pointer;
    transition: color 0.3s;
    min-width: 168px;

    &:not(:last-child) {
      margin-bottom: 28px;
    }

    &:hover {
      color: var(--color-text-primary);
    }

    &.stepsMenuItemActive {
      font-weight: 600;
      color: var(--color-text-primary);
    }
  }

  .mobileMenuTitle {
    display: flex;
    align-items: center;

    svg {
      margin-left: 12px;
    }
  }

  @media (max-width: 1400px) {
    flex: 0 0 180px;
  }
  @media (max-width: 850px) {
    flex: 0 0 auto;
    margin-bottom: 24px;
  }
}

.modalTitle {
  position: relative;
  svg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.list{
  >*{
    padding: 0 16px;
    height: 56px;
    display: flex;
    align-items: center;
  }
  .activeListItem{
    background: rgba(54, 54, 54, 0.1);
    border-radius: 8px;
  }
}
