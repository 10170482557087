@keyframes tabContentAnim {
  from {
    transform: translate(-100%, 0px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes tabContentAnimRtl {
  from {
    transform: translate(100%, 0px);
  }
  to {
    transform: translate(0, 0);
  }
}
.head{
  margin-bottom: 32px;
}

.tabContent {
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &.lft {
    animation-name: tabContentAnim;
    animation-duration: 0.35s;
  }
  &.rtl {

    animation-name: tabContentAnimRtl;
    animation-duration: 0.35s;

  }
}
@media (max-width: 500px) {
  .head{
    margin-bottom: 16px;
  }
}
@media (max-width: 350px) {
  .head *{
    font-size: 14px;
  }
}
