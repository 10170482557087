.modalContent {
  max-width: 416px;
  height: 145px;
  width: 100%;

  /* 24 dp */

  filter: drop-shadow(0px 13px 40px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  .modalActions {
    display: flex;
    align-items: center;
    margin-top: 47px;

    button:first-child {
      margin-right: 16px;
    }
  }
}

.wrap {
  width: 100%;

  .title {
    margin-bottom: 16px;
  }

  .tabsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .instrWrap{
      display: flex;
      flex-direction: column;
    }

    .instructionLink {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      color: #148F2A;

      svg {
        margin-left: 4px;
        margin-bottom: 3px;
      }
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    margin-top: 46px;


    .tabContent {
      flex: 1 1 auto;
    }
  }

  @media (max-width: 850px) {
    .content {
      flex-direction: column;
    }
  }
}
