.wrap {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    align-items: center;

    img {
      margin-right: 24px;
    }

    p {
      margin: 8px 0 16px;
    }
  }
}


@media screen and (max-width: 767px) {
  .wrap{
    .content {
      flex-direction: column;

      img {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .wrap {
    .content {
      justify-content: center;

      > div {
        text-align: center;
      }

      button {
        width: 100%;

        span {
          text-align: center;
        }
      }
    }
  }
}
