.mainPage{
  .hrefAccordion{
    cursor: pointer;
    :global{
      .default-MuiAccordionSummary-expandIcon{
        transform: rotate(-90deg);
      }
    }
  }
  .mobileVersBlock{
    margin-top: 40px;
    .mobileVersion{
      margin-bottom: 12px;
    }
  }
  .title{
    display: flex;
    align-items: flex-start;
    position: relative;
    .illustration{
      position: absolute;
      right: 0;
      top: -92px;
      z-index: -1;
    }
    .text{
      // margin-left: 32px;
      width: 100%;
      position: relative;
      span{
        display: block;
        margin-bottom: 16px;
      }

    }
  }
  .cardWrap{
    display: flex;
    margin: 72px 0 56px;
    .card{
      & > * {
        &:hover{
          background: #148F2A;
        }
      }
      &:first-child{
        >div{
          background: linear-gradient(247deg, #248A0A -61.86%, #268A24 -15.75%, rgba(10, 138, 38, 0.00) 52.26%), #24282B;
          &:hover{
            background: linear-gradient(257deg, #148F2A -11.76%, #148F2A 111.94%, rgba(20, 143, 42, 0.00) 149.93%),#148F2A;
          }
        }
      }
      :global{
        .FuiAvatar{
          font-size: 24px;
        }
        .default-MuiAvatar-colorDefault{
          background: var(--gradients-green, linear-gradient(36deg, #24B23E 1.15%, #AFED00 95.82%));
        }
        .FuiTypography{
          color: rgba(255, 255, 255, 0.98);
        }
      }
      flex: 1 1 auto;
      cursor: pointer;

      &:not(:last-child){
        margin-right: 32px;
      }
    }
  }
  .questionsWrap{

    .head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 42px;
    }
    .withoutBorder{
      border-bottom: none;
    }
  }
}
@media (max-width: 600px) {
  .mainPage{
    .title{
      .text{
        margin-left: 0;
      }
      .illustration{
        top: -35px;
      }
    }
    .cardWrap{
      flex-direction: column;
      margin: 56px 0;
      .card{
        &:not(:last-child) {
          margin-bottom: 32px;
          margin-right: 0;
        }
      }
    }
  }
}
