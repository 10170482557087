.webWrap {
  text-align: center;
  margin: 130px auto 0;
  max-width: 305px;

  h6 {
    margin-bottom: 16px;
  }
}

.mobileWrap {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;

  h5 {
    margin-bottom: 12px;
  }

  .pdfWrap {
    padding: 0;
    margin-bottom: 32px;

    :global {
      .default-MuiListItemSecondaryAction-root {
        margin: 6px 0 6px;
      }
    }
  }

  .accordionContent {
    width: 100%;

    .text {
      margin: 32px 0 56px;
    }

    .publickKey {
      overflow-wrap: break-word;
      margin: 16px 0;
    }
  }

  .description {
    margin: 16px 0;
    flex: 1 1 auto;
  }

  .deleteTextIcon {
    font-size: 16px;
  }

  .step2 {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .mb16 {
    margin-bottom: 16px;
  }
}

.alertWrap {
  flex: 1 1 auto;
  margin-bottom: 16px;
}
