@keyframes appearSvg {
  to {
    stroke-dasharray: 36;
  }
}

@keyframes appearTextAlert {
  to {
    opacity: 1;
  }
}
.successAlert {
  opacity: 0;
  background: rgba(33, 160, 56, 0.08);
  border-radius: 8px;
  color: var(--color-primary2);
  display: flex;
  align-items: center;
  padding: 12px 20px;
  animation-name: appearTextAlert;
  animation-delay: 0.1s;
  animation-duration: 0.29s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  svg {
    margin-right: 10px;
    stroke-dashoffset: -72;
    stroke-dasharray: 24;
    animation-name: appearSvg;
    animation-delay: 0.3s;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .textAlert {
    opacity: 0;
    animation-name: appearTextAlert;
    animation-delay: 0.8s;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
}
