.wrap{
  background-color: #ffffff;
  padding: 24px;
  .text{
    margin: 16px 0;
  }
  .btnWrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button:first-child{
      margin-right: 16px;
    }
  }
}
