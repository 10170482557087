.wrap {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1 1 auto;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
  }
  .footer{
    display: flex;
    height: 80px;
    align-items: center;
    span{
      margin-left: 40px;
      font-family: SBSansText;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Gray / 800 */

      color: var(--color-gray-800);
    }
  }
  @media (max-width: 600px) {
    .content{
      padding-top: 40px;
    }
  }
}
