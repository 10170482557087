.searchWrap {
  max-width: 351px;
  margin-bottom: 24px;
}
.backButton{
  align-self: flex-start;
}
.textSecondary {
  color: var(--color-text-secondary);
}

.headDelegate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 8px;

  .tableActions {
    display: flex;
    justify-content: flex-end;

    .deleteAllButton {
      color: var(--color-text-secondary);
      margin-right: 12px;
    }
  }
}

.actions {
  margin: 16px 0 0;
  display: flex;
  align-items: flex-start;

  > button {
    margin-left: 16px;
  }
}

.subText {
  margin-top: 16px;
  color: var(--color-gray-700);
}

.mobileTable {

  .mobileTableItem {
    padding: 12px 0 16px;
    border-bottom: 1px solid rgba(54, 54, 54, 0.08);

    &:first-child {
      border-top: 1px solid rgba(54, 54, 54, 0.08);
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      Button{
         flex: 0 0 auto;
      }
    }
  }
}

.errorWrap{
  padding-top: 20px;
}

@media (max-width: 767px) {
  .headDelegate {
    flex-direction: column;
    align-items: flex-start;

    .tableActions {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .deleteAllButton {
        margin-right: 0;
      }
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 14px;
    }
  }
}

@media (max-width: 500px) {
  .searchWrap {
    margin-bottom: 0;
  }
  .errorWrap{
    button{
      width: 100%;
    }
  }
}

@media (max-width: 350px) {
  .headDelegate {
    .tableActions * {
      font-size: 12px;
    }
  }

}
