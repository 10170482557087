.tableHead {
  th {
    color: var(--color-text-secondary);
  }
}

.tableBody {

  .clickableRow{
    transition: 0.3s;
    cursor: pointer;
    &:hover{
      background-color: var(--color-bg-lightgray);
    }
  }
}
