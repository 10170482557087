.sidePanelActions{
  margin-top: auto;
  Button:first-child{
    margin-right: 16px;
  }
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  .actions {

  }

  .logo {
    font-family: SBSansDisplay, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    /* identical to box height, or 222% */

    letter-spacing: -0.025em;
    text-transform: uppercase;

    /* Мастер/Изумрудный */
    color: var(--color-emerald);
  }
}
