.list{
  margin: 64px 0 0;
  padding: 0;
  counter-reset: section;
  li{
    margin-bottom: 32px;
    list-style-type: none;
    padding-left: 44px;
    position: relative;
    &:before{
      position: absolute;
      left: 0;
      counter-increment: section;
      content: counter(section);
      border-radius: 100px;
      border: 1px solid var(--color-text-primary);
      /* Body 1 */
      font-family: SBSansText;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.304px;
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }
}
