.nameWrap{
  display: flex;
  align-items: center;
  .iconName{
    font-size: 24px;
    color: rgba(54, 54, 54, 0.32);
    margin-right: 12px;
  }
}
.instructionPdf{
  display: flex;
  align-items: center;
  margin: 16px 0 24px;
  color: var(--color-primary2);
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
  svg{
    margin-left: 4px;
    margin-bottom: 2px  ;
  }
}
.emptyList {
  padding: 110px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;


  .text {
    margin-bottom: 20px;
    max-width: 398px;
    text-align: center;
  }
  button{
    max-width: 398px;
    width: 100%;
    margin-bottom: 16px;
  }

}
.statusWrap{
  display: flex;
  align-items: center;
  .status{
    padding: 4px 8px;
    border-radius: 55px;
    margin-right: 8px;

    /* Light/Background/Tertiary */
    background: rgb(228, 235, 240);
    &.error{
      color: rgba(255, 255, 255, 0.98);
      /* Light/Foundation/Error */
      background: rgb(227, 18, 39);
    }
  }
}
.myDevicesBody {
  display: flex;

  .appsWrap {
    max-width: 256px;
    margin-left: 32px;
    .cyberPassInstrAlert{
      margin-bottom: 32px;
      >div{
        >div{
          >div{
            padding: 0;
          }
        }
      }
    }
    .qrCodeWrap {
      display: flex;
      margin-bottom: 4px;

      > svg {
        margin-right: 10px;
      }

      a {
        display: inline-flex;
        align-items: center;

        svg {


          margin-left: 4px;
        }
      }
    }
  }

  .tableWrap {
    flex: 1 1 auto;
    .tableActions {
      gap: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 8px;

      .deleteAllButton {
        color: var(--color-text-secondary);
      }
    }

  }

}

.step1 {
  margin-bottom: 32px;

  .buttonsWrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    button {
      flex: 1 1 100%;
      background-color: transparent;
      border: 1px solid var(--color-text-primary);

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
}


.step2 {
  flex: 1 1 auto;

  .actions {
    display: flex;
    align-items: flex-start;

    > button {
      margin-left: 16px;
    }
  }
}

.downloadWrap {
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
    flex: 0 0 auto;
  }
}


.textSecondary {
  color: var(--color-text-secondary);
}

.mobileTable {

  .mobileTableItem {
    padding: 12px 0 16px;
    border-bottom: 1px solid rgba(54, 54, 54, 0.08);

    &:first-child {
      border-top: 1px solid rgba(54, 54, 54, 0.08);
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      button {
        flex: 0 0 auto;
      }
    }
  }
}

.cyberPassInstrWrap{
  margin-bottom: 32px;
  .cyberPassInstr{
    h6{
      display: flex;
      align-items: center;
    }
    ol {
      list-style-type: none; /* Убираем исходные маркеры */
      counter-reset:  item; /* Обнуляем счетчик списка */
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        &:not(:last-child){
          margin-bottom: 20px;
        }
        &:before{
          content: counter(item); /* Добавляем к числам скобку */
          counter-increment: item; /* Задаём имя счетчика */
          border: 1px solid;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          /* Body 1 */
          color: rgb(38, 38, 38);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -1.9%;
          text-align: center;
          margin-right: 12px;
        }
      }
    }
    .qrWrap{
      padding-left: 45px;
      display: flex;
      margin: 8px 0 52px;
      .qrText{
        margin-left: 5px;
        >p{
          padding-left: 12px;
        }
        >Button{
          width: 100%;
        }
      }
    }

  }
}

.cyberPassInstrMobWrap{
  height: 100%;
  display: flex;
  flex-direction: column;

  .imgWrap{
    flex: 1 1 auto;
    margin: 36px 0;
    >img{
      width: 100%;
      height: auto;
    }
  }
  h5{
    display: flex;
    align-items: center;
  }

}

.maxDevicesWrap{
  margin: 32px 0 16px;
  padding-left: 16px;
}

@media (max-width: 1200px) {
  .myDevicesBody {
    flex-direction: column;

    .appsWrap {
      margin: 32px 0;
    }
  }
}

@media (max-width: 767px) {
  .myDevicesBody {
    .appsWrap {
      max-width: 100%;

      .qrCodeWrap {
        p {
          white-space: initial;
        }

        > svg {
          display: none;
        }
      }
    }
  }
  .maxDevicesWrap{
    margin: 16px 0 8px;
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .devicesErrorWrap{
    button{
      width: 100%;
    }
  }
  .myDevicesBody {
    .appsWrap {

      p {
        font-size: 14px;
      }
    }

    .tableWrap {
      .tableActions * {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 350px) {
  .myDevicesBody {
    .tableWrap {
      .tableActions * {
        font-size: 12px;
      }
    }
  }
}

:global{
  .iosAddCheckbox{
    .MuiFormControlLabel-label{
      padding-top: 0;
    }
  }
}
