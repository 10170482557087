.wrap{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .fieldWrap{
    margin-top: 16px;
    display: flex;
    align-items: center;
    >div {
      margin-right: 32px;
    }
  }
  .alertWrap{
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
  }
}
:global{
  .default-MuiSnackbar-root{
    img{
      max-height: 200px;
      width: 100%;
      object-fit: fill;
    }
  }
}

@media screen and (max-width: 599px) {
  .wrap{
    .fieldWrap{
      flex-direction: column;
      align-items: flex-start;
      >div{
        margin-right: 0;
      }
      >p{
        margin-top: -8px;
      }
    }
    button{
      margin-top: 24px;
      width: 100%;
    }
    .alertWrap{
      width: 100%;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
}
