@font-face {
  font-family: "SBSansDisplay";
  font-weight: 300;
  src: url("./fonts/SBSansDisplay/Light/SBSansDisplay-Light.woff")
      format("woff"),
    url("./fonts/SBSansDisplay/Light/SBSansDisplay-Light.woff2") format("woff2"),
    url("./fonts/SBSansDisplay/Light/SBSansDisplay-Light.ttf") format("ttf"),
    url("./fonts/SBSansDisplay/Light/SBSansDisplay-Light.eot") format("eot");
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: normal;
  src: url("./fonts/SBSansDisplay/Normal/SBSansDisplay-Regular.woff")
      format("woff"),
    url("./fonts/SBSansDisplay/Normal/SBSansDisplay-Regular.woff2")
      format("woff2"),
    url("./fonts/SBSansDisplay/Normal/SBSansDisplay-Regular.ttf") format("ttf"),
    url("./fonts/SBSansDisplay/Normal/SBSansDisplay-Regular.eot") format("eot");
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: 600;
  src: url("./fonts/SBSansDisplay/SemiBold/SBSansDisplay-SemiBold.woff")
      format("woff"),
    url("./fonts/SBSansDisplay/SemiBold/SBSansDisplay-SemiBold.woff2")
      format("woff2"),
    url("./fonts/SBSansDisplay/SemiBold/SBSansDisplay-SemiBold.ttf")
      format("ttf"),
    url("./fonts/SBSansDisplay/SemiBold/SBSansDisplay-SemiBold.eot")
      format("eot");
}
@font-face {
  font-family: "SBSansDisplay";
  font-weight: bold;
  src: url("./fonts/SBSansDisplay/Bold/SBSansDisplay-Bold.woff") format("woff"),
    url("./fonts/SBSansDisplay//Bold/SBSansDisplay-Bold.woff2") format("woff2"),
    url("./fonts/SBSansDisplay/Bold/SBSansDisplay-Bold.ttf") format("ttf"),
    url("./fonts/SBSansDisplay/Bold/SBSansDisplay-Bold.eot") format("eot");
}
@font-face {
  font-family: "SBSansText";
  font-weight: normal;
  src: url("./fonts/SBSansText/regular/SBSansText-Regular.woff") format("woff"),
    url("./fonts/SBSansText/regular/SBSansText-Regular.woff2") format("woff2"),
    url("./fonts/SBSansText/regular/SBSansText-Regular.ttf") format("ttf"),
    url("./fonts/SBSansText/regular/SBSansText-Regular.eot") format("eot");
}

@font-face {
  font-family: "SBSansText";
  font-weight: 500;
  src: url("./fonts/SBSansText/medium/SBSansText-Medium.woff") format("woff"),
    url("./fonts/SBSansText/medium/SBSansText-Medium.woff2") format("woff2"),
    url("./fonts/SBSansText/medium/SBSansText-Medium.ttf") format("ttf"),
    url("./fonts/SBSansText/medium/SBSansText-Medium.eot") format("eot");
}

@font-face {
  font-family: "SBSansText";
  font-weight: 600;
  src: url("./fonts/SBSansText/semibold/SBSansText-Semibold.woff")
      format("woff"),
    url("./fonts/SBSansText/semibold/SBSansText-Semibold.woff2") format("woff2"),
    url("./fonts/SBSansText/semibold/SBSansText-Semibold.ttf") format("ttf"),
    url("./fonts/SBSansText/semibold/SBSansText-Semibold.eot") format("eot");
}
@font-face {
  font-family: "SBSansText";
  font-weight: bold;
  src: url("./fonts/SBSansText/bold/SBSansText-Bold.woff") format("woff"),
    url("./fonts/SBSansText/bold/SBSansText-Bold.woff2") format("woff2"),
    url("./fonts/SBSansText/bold/SBSansText-Bold.ttf") format("ttf"),
    url("./fonts/SBSansText/bold/SBSansText-Bold.eot") format("eot");
}
@font-face {
  font-family: "SBSansText";
  font-weight: bold;
  font-style: italic;
  src: url("./fonts/SBSansText/bold_italic/SBSansText-BoldItalic.woff")
      format("woff"),
    url("./fonts/SBSansText/bold_italic/SBSansText-BoldItalic.woff2")
      format("woff2"),
    url("./fonts/SBSansText/bold_italic/SBSansText-BoldItalic.ttf")
      format("ttf"),
    url("./fonts/SBSansText/bold_italic/SBSansText-BoldItalic.eot")
      format("eot");
}
@font-face {
  font-family: "Monoton";
  font-weight: normal;
  src: url("./fonts/Monoton/Monoton-Regular.ttf");
}
