:global{
  .default-MuiSnackbar-root{
    img{
      max-height: 200px;
      width: 100%;
      object-fit: fill;
    }
  }
}
.tableWrap {
  display: flex;
  .table{
    flex: 1 1 auto;
    .activateOmega{
      float: right;
      margin-bottom: 12px;
    }
  }
  .infoBoard {
    margin-left: 35px;
    max-width: 260px;

    > a {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;

      > svg {
        font-size: 16px;
        margin-left: 4px;
      }
    }

    ul {
      padding-left: 25px;
      margin-top: 10px;
    }
    & :global{
      .default-MuiAlert-message{
        font-size: 14px;
      }
    }
  }
}
.emptyList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 110px;

  > div{
    margin-left: 24px;
  }

  .text {
    margin-bottom: 16px;
    color: var(--color-text-secondary);
    max-width: 334px;
  }
  .buttonsWrap{
    display: flex;
    align-items: center;
    > *:not(:last-child){
      margin-right: 16px;
    }
  }
}
@media (max-width: 768px) {
  .emptyList {
    flex-direction: column;
    padding-top: 16px;

    > div {
      margin-left: 0;
    }
    .buttonsWrap{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > *{
        margin-bottom: 16px;
      }
    }
  }
  .tableWrap {
    flex-direction: column;

    .infoBoard {
      margin-left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media (max-width: 450px) {
      .infoBoard {
        max-width: 100%;
      }
      .btnText {
        display: none;
      }
      .refetchTokenListBtn{
        width: 100%;
      }
    }
  }
}



.mb16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px;
}

.textSecondary {
  color: var(--color-text-secondary);
}

.step {
  margin-bottom: 32px;
}

.pinHelperText {
  margin: 12px 0 20px;

  span {
    display: flex;
    align-items: center;

    .icon {
      display: inline-flex;
      margin-right: 8px;
      svg{
        font-size: 16px;
      }
    }
  }

  .success {
    color: var(--color-primary2);
  }

  .error {
    color: var(--color-error);
  }
}

.field {
  display: flex;
  align-items: center;

  > P {
    margin-left: 16px;
    margin-bottom: 22px;
    color: var(--color-gray-800);
  }
}

.confirmModalWrap {
  h6 {
    width: 100%;
    padding: 0 16px;
  }

  p {
    padding: 0 16px;
  }
}

.bottomAlert {
  flex: 1 1 auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 450px) {
  .mobileBtn {
    width: 100%;
    span{
      text-align: center;
    }
  }
}
