.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 78px 0 16px;

  .content {
    flex: 1 1 auto;
    max-width: 352px;

    h5 {
      margin-bottom: 4px;
    }

    .tabsWrap {
      margin: 24px 0;
    }

    .buttonsWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .AdBlockedError {
    flex: 1 1 auto;
    max-width: 448px;

    .header {
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }


    .phoneField {
      a {
        text-decoration: none;
        color: inherit;
        font-weight: 600;
      }
    }

    .greenLink {
      text-decoration: none;
      color: #148F2A;
      font-weight: 600;
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 4px;
        margin-bottom: 2px;
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .wrap {
    padding: 0 0 16px;
  }
}
