.wrap {
  display: flex;
  margin-bottom: 32px;
  .text {
    display: flex;
    flex-direction: column;
    margin-right: 72px;
    max-width: 500px;
    flex: 1 1 auto;

    .title {
      margin-bottom: 8px;
    }

    .subTitle {
      margin-bottom: 32px;
    }

    .actionsWrap {
      display: flex;
      flex-direction: column;

      .actionsItem {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        color: var(--color-primary2);
        transition: all 0.3s;

        span {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-right: 12px;
          width: 32px;
          height: 32px;
          font-weight: 400;
          font-size: 16px;


          border: 1px solid currentColor;
          border-radius: 100px;

        }

        &.activeItem {
          color: var(--color-text-primary);

          span {
            background-color: #363636;
            color: #ffffff;
          }
          .itemTextWrap {
            a {
              svg *{
                fill-opacity: 1;
              }
            }
          }
        }

        &:hover {
          color: var(--color-text-primary);

          &.activeItem {
            color: var(--color-text-primary);
          }
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .itemTextWrap {
          white-space: pre-line;
          a{
            display: inline-flex;
            align-items: center;
            svg {
              margin-left: 4px;
              margin-bottom: 2px;
              *{
                fill: currentColor;
                fill-opacity: 0;
              }
            }
          }
          .itemSubTitle {
            display: flex;
            align-items: center;
            margin-top: 8px;

            >svg {
              margin-right: 10px;
            }

            p {
              margin: 0;

            }
          }
        }
      }

    }

  }
  .buttonsWrap{
    display: flex;
    align-items: center;
    margin-top: 32px;
    .prevStepButton{
      margin-right: 16px;
      width: 74px;
    }
    .nextStepButton{
      min-width: 87px;
    }
  }
  .imageWrap {
    flex: 1 1 auto;
    max-width: 416px;
    min-width: 188px;
    max-height: 397px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .bgImg{
      position: absolute;
      z-index: 1;
      border: 10px solid #fff;
      border-radius: 40px;
      height: auto;

    }
   .slider{
     width: 188px;
     &.hideSlider{
       display: none;
     }
   }
    .widePicture{
      width: 100%;
      height: auto;
    }
    img{
      height: 100%;
    }
  }
  .mobileChildItem{
    display: inline-flex;
    align-items: flex-start;


    span {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 12px;
      width: 32px;
      height: 32px;
      font-weight: 400;
      font-size: 16px;


      border: 1px solid currentColor;
      border-radius: 100px;

    }
  }
  .mobileImg{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 280px;
    margin: 16px 0;
    img{
      height: 100%;
      width: auto;
    }
    .widePicture{
      width: 100%;
      height: auto;
    }
  }
  :global{
    .Step4subTitle{
      margin-top: 16px;
      >div {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        img{
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .text {
      margin-right: 28px;
    }
  }
  @media (max-width: 850px) {
    flex-direction: column;
    :global{
      .Step4subTitle{
       display: none;
      }
    }
  }
  @media (max-width: 600px) {
    .buttonsWrap{
      >Button{
        flex: 1 1 auto;
      }
    }
  }
}
