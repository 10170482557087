@import "./fonts.scss";

* {
  box-sizing: border-box;
}

html {
  font-family: SBSansText, sans-serif;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  width: 100%;

  body {
    background-color: #ffffff;
  }
}

body {
  // ------------- Colors -------------- //
  --color-text-primary: rgba(0, 0, 0, 0.87);
  --color-primary2: #148F2A;
  --color-text-secondary: rgba(0, 0, 0, 0.6);
  --color-light-backgroundTertiary: #E4EBF0;
  --color-bg-lightgray: #f8f8f8;
  /* Мастер/Изумрудный */
  --color-emerald: #21BA72;
  // -------- Gray --------
  --color-gray-800: #4E4E4E;
  --color-gray-700: #707070;
  // -------- Green --------
  --color-green-a50: #ebffef;
  --color-green-a100: #c4facf;
  --color-green-a200: #9ff4b1;
  --color-green: #00a523;
  --color-green-dark: #02811e;
  --color-green-darkest: #045517;
  // -------- Blue ---------
  --color-blue: #6dc4ff;
  // --------- Red ---------
  --color-red-a50: #ffebeb;
  --color-red-a100: #ffd1d1;
  --color-red-a200: #ffb6b6;
  --color-error: #E31227;
  --color-error-dark: #b11818;
  --color-error-darkest: #701717;
  /* Light/Foundation/Warning */
  --color-foundation-warning: #E35502;

  // ----------------------------------- //
  color: var(--color-black);
  margin: 0;
  padding: 0;
  min-height: 100%;

  .MuiButton-containedPrimary {
    background: var(--color-primary2);
  }
}

input[type="checkbox"] {
  cursor: pointer;
  margin: 0;
  width: 20px;
  height: 20px;
  border-color: rgba(0, 0, 0, 0.6);;
}

h1,
h2,
h3,
h4 {
  font-family: SBSansDisplay;
}

h5 {
  font-family: SBSansText;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
}
.error-color{
  color: var(--color-error);
}

h6 {
  font-family: SBSansText;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
}

.errorText {
  display: inline-block;
  margin-right: 15px;

  a {
    color: inherit;
  }
}

.green-link {
  color: var(--color-primary2);
  color: #148F2A;
  text-decoration: none;
  word-break: break-word;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.green-label {
  color: #00a523;
  color: var(--color-green);
}

.content-pre-line {
  white-space: pre-line;
}

.MuiSnackbar-root {
  position: fixed;
}

.default-MuiSnackbar-root {
  position: fixed;
}


.container {
  width: 100%;
  max-width: 1344px;
  padding: 0 16px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    max-width: 1152px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}


